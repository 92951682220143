import type { FC } from "react";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import CustomQuestionnaire from "src/ui/components/CustomQuestionnaire/CustomQuestionnaire";

const CustomQuestionnaireStandalone: FC = () => {
  const { id = "" } = useParams<{ id: string }>();

  const questionnaireInstanceId = useMemo(() => {
    const url = new URL(window.location.href);
    const instanceId = url.searchParams.get("instanceId");
    return instanceId ? `:${instanceId}` : ":standalone";
  }, []);

  return (
    <CustomQuestionnaire
      disableTracking
      disableAutoSubmit
      preventLoadingUserAnswers
      instanceId={questionnaireInstanceId}
      autoSkip={false}
      formLike
      id={id}
    ></CustomQuestionnaire>
  );
};

export default CustomQuestionnaireStandalone;
